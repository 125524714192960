export default {
  LONG_TITLE_LENGTH: 50, // the lenght in chars when row size is increased
  LONG_LEARNING_LENGTH: 120, // the lenght in chars when row size is increased

  // if the length of save or saved text in any language is greater than LONG_SAVED_TEXT_LENGTH
  // SMALL_SAVED_TEXT_SIZE wil be set as fontSize, otherwise NORMAL_SAVED_TEXT_SIZE
  LONG_SAVED_TEXT_LENGTH: 8,
  SMALL_SAVED_TEXT_SIZE: "0.75rem",
  NORMAL_SAVED_TEXT_SIZE: "1rem",

  SCREEN_LG_MAX: 1200,
  SCREEN_SM_MAX: 768,
  //SCREEN_SM_MAX: "(max-device-width: 768px)",

  //if number of ratings is less than this number (set in backend) caching is not used,
  // then refecthing after a rating has changed is not necessary.
  NUM_RATINGS_WHEN_TO_USE_CACHING: 5,

  // MiniBook statuses
  DRAFT: "draft",
  PUBLISHED: "published",
  DELETE_PENDING: "delete_pending",

  // Language Codes
  FINNISH: "fi",
  FI: "fi",
  ENGLISH: "en",
  EN: "en",

  // Currency Codes
  EURO: "eur",
  EUR: "eur",
  DOLLAR: "usd",
  USD: "usd",

  // session keys
  LEARNINGSSEARCHFILTERSTATE: "learningsSearchFilterState",
  LEARNINGSFILTERSTATE: "learningsFilterState",
  BOOKSFILTERSTATE: "booksFilterState",

  // session data strings
  TOPRATED: "TopRated",
  NEWEST: "Newest",

  // search results
  SEARCHMAXNUMLEARNINGS: 200,
  SEARCHMAXNUMBOOKS: 200,
  SEARCHPAGESIZE: 20,
  SEARCHNUMPAGES: 10,
  // Maximum character lengths. Should correspond with backend values
  MAX_BIO: 200,
  MAX_LEARNING: 200,
  MAX_STEP1: 80,
  MAX_STEP2: 30000,
  MAX_STEP3: 30000,
  MAX_STEP4: 20000,
  MAX_STEP5: 20000,

  // Regex string to only include alphabetic characters, including accents. Punctuation and spaces allowed.
  RE_ALPHABETIC: /^[\p{Alpha}\p{P}\p{Z}\t\r\n\v\f0-9]+$/u,
  // For reference. Cannot be used within a t() function.
  RE_ALPHABETIC_MSG: "validation>No special characters allowed.",
  // Username validator used in backend
  RE_USERNAME: /^[\w.@+-]+(\s+[\w.@+-]+)*$/,
  // Regex letters, numbers, and dot(.) underline(_) characters.
  IG_USERNAME: /^[a-zA-Z0-9._]+$/,
  // For reference. Cannot be used within a t() function.
  RE_USERNAME_MSG:
    "validation>Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters.",
};
